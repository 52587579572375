
	import { defineComponent, ref, onMounted,reactive  } from 'vue';
	import { useRouter } from 'vue-router';
	import ptBr from 'element-plus/lib/locale/lang/pt-br';
	import moment from 'moment';
	import { getOficinaDigitalHistoricoOrcamentos } from "@/services/OficinaDigital";
	import HistoricoFaturamentoCard from "./components/HistoricoFaturamentoCard.vue";
	import PercentualCategoriasCard from "./components/PercentualCategoriasCard.vue";
	import HistoricoOrcamentosRealizados from "./components/HistoricoOrcamentosRealizados.vue";
	import ConsultoresCard from "./components/ConsultoresCard.vue";	
	import OrcamentosFinalizadosCard from "@/views/oficina-digital/historico/components/OrcamentosFinalizadosCard.vue";
	import { getFaturamento, getOficinadigitalfinalizadosParte1, getGraficoHistoricoRealizado, getOficinadigitalfinalizadosParte4, getConsultores , getMecanicos } from "@/services/OficinaDigital";
	import useEmitter from '@/composables/Emmiter';
	import { getFaturamentosTotais } from "@/services/OnePageReportService";
	import {ContentLoader} from 'vue-content-loader';
	import useAlert from "@/composables/Alert";
	import {IFaturamento,IGrafico,GetChartHistoricoOrcamento} from "@/models/OficinaDigitalRelatorioCompletoModel";


	export default defineComponent( {
		name: "finalizados",
		components: {
			HistoricoFaturamentoCard,
			PercentualCategoriasCard,
			HistoricoOrcamentosRealizados,
			ConsultoresCard,
			OrcamentosFinalizadosCard,
			ContentLoader
		},
		props: {
			filters: Object,
			filtersInicial: Object
		},

        setup(props) {
			const { showTimeAlert } = useAlert();
			const historicoContatoPeriodo = ref("");	
			const emitter = useEmitter();
			const emptyArray: Array<any> = [];
			const codConcessionaria = ref();
			const dadosHistoricoOrcamento = ref([]);
			const filters = ref("");
			const checklistCount = ref(0);
			const totalOrcamentos:any = ref({
				faturado: 0,
				naoFaturado: 0,
				negociacao: 0,
				quantidade: 0
			});
			const percentualRecuperado = ref();
			const propsConcessionaria = ref({});
			const orcamentos = ref({
				faturado: 0,
				naoFaturado: 0,
				negociacao: 0,
				quantidade: 0
			});
			const aprovados = ref(0);
			const revisoes = ref(0);
			const orcamentosParcial = ref({
				faturado: 0,
				naoFaturado: 0,
				negociacao: 0,
				quantidade: 0
			});
			const orcamentoReprovados = ref({
				faturado: 0,
				naoFaturado: 0,
				negociacao: 0,
				quantidade: 0
			});
			const title = ref('Percentual categorias')
			const labels = 0; 
			const percentual = ref();
			const historicoOrcamentos = ref<GetChartHistoricoOrcamento>({
				categorias: [],
				series: [],
				seriesAprovados: []
			});
			const faturamento = ref();
			let loader = ref(true);
			let loadingHistoricoOrcamento = ref(true);
			const router = useRouter();
			let filter = ref(true);
			let orcamento = ref('');
			let animate = ref(true)
			const temRevisoes = ref(true);

			// Declara filtros
			const datePicker:any = ref([moment().startOf("day").subtract(30, 'days'), moment().startOf("day")]);
			const consultorSelected:any = ref(emptyArray)	;		
			const lastChangeIsNull = ref(true);
			const consultores = ref(emptyArray);
			const mecanicos = ref(emptyArray);
			const aceitaramNegociacao = ref();
			const dadosChecklist = ref({});

			//apagar ou alterar				
			const dataFaturamentoTotal = ref([]);
			const loadingFaturamentoTotal = ref(false);
			const metasConcessionaria = ref();
			const faturamentoValores =  ref<IFaturamento>({
				faturamentoTotal: 0,
				faturamentoPecas: 0,
				faturamentoPrecoMO: 0,
				faturamentoTempoMO: 0,
				faturamentoMO: 0
			})
			const loadingFaturamentoGraficoDiario = ref(true)
			const graficoDiario = ref<IGrafico[]>([])
			const loadingConsultores = ref(true)
			const loadingMecanicos = ref(true)
			const loadingGraficoHistoricoOrcamentoRealizado = ref(true)
			const percentuaisGeral = reactive({
				percentualChecklist: 0,
				percentualOrcamento: 0,
				totalChecklists: 0,
				totalOrcamento: 0,
				totalRevisoes: 0,
				totalmenteAprovado: 0,
				totalmenteReprovado: 0,
				percentualOrcamentoAprovado: 0
			})

			onMounted(async () => {
				emitter.emit("getFilters", true);
			});

			emitter.on("filtrar-od-finalizados", async params  => {
				trataFilters(params.data);
				codConcessionaria.value = params.codConcessionaria;
				listaFinalizados(params.codConcessionaria, params.data, params.consultorSelected, params.placa);

				const periodoSelecionado = params.data.split("/");
				const dataInicial = moment(periodoSelecionado[0], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
				const dataFinal = moment(periodoSelecionado[1], "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");

				const postData = {
					placa: params.placa && params.placa != "null" ? params.placa : null,
                    consultor: params.consultorSelected && params.consultorSelected != "null" ? Number(params.consultorSelected) : null,
                    periodo: (periodoSelecionado[0] && periodoSelecionado[0] != "null") && (periodoSelecionado[1] && periodoSelecionado[1] != "null") ? {
                        dataInicial,
                        dataFinal,
                    } : null
				};
				
				propsConcessionaria.value = {
					codConcessionaria: params.codConcessionaria,
					data: {
						inicial: dataInicial,
						final: dataFinal
					},
					codConsultor: params.consultorSelected,
					placa: params.placa,
				}

				getOrcamentos(postData);
            });
					
			function trataFilters(data) {	
				/* console.log("trataFilters", trataFilters) */			
				let oneMounth = false;
				let title = ""; 
				data = data.split("/");
				const dataInicial = moment(data[0]);
				const dataFinal = moment(data[1]);
				const mesmoAno = dataFinal.year() === dataInicial.year();
				const mesmoMes = dataFinal.month() === dataInicial.month();
				if (!(mesmoAno && mesmoMes)) {
					return {
						data: data[0] + "/" + data[1],
						oneMounth,
						title: `entre ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")} e ${dataFinal.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`
					}
				};
				const diferencaMesFinalAteAtual = Math.round(dataFinal.diff(moment(), 'months', true));
				if (diferencaMesFinalAteAtual == 0) {
					title = "nos últimos 6 meses";
				} else {
					title = `nos 6 meses antecessores a ${dataFinal.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
				};

				oneMounth = true;
				const dataFinalFormat = dataFinal.format('YYYY-MM-DD');
				const sixMonths = dataFinal.subtract(5, 'months').format('YYYY-MM-DD');
				const dataTratada = sixMonths + "/" + dataFinalFormat;
				return {
					data: dataTratada,
					oneMounth,
					title
				};
			}
	           
			function goTo(routeName){
				router.push({
					name: routeName
				});
			};

			async function faturamentoDaOficina(filtersData){
                const response2 = await getFaturamentosTotais(codConcessionaria.value, filtersData);		
                dataFaturamentoTotal.value = response2;		
	        }			

			async function getOrcamentos(postData){
				loadingHistoricoOrcamento.value = true;
				try {
					const response = await getOficinaDigitalHistoricoOrcamentos(codConcessionaria.value, postData);
					dadosChecklist.value = response.dadosChecklist;

					let checklists = response.checklists.filter((checklist, index) => index < 3);
					checklists.forEach(checklist => {
						checklist.codChecklist = checklist.codCheckList;
						checklist.dataEntradaTratada = moment(checklist.dataEntrada, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
						if(checklist.qtdAprovados > 0 && checklist.qtdReprovados == 0){
							checklist.tipoOrcamento = 1;
						} else if (checklist.qtdAprovados > 0 && checklist.qtdReprovados > 0) {
							checklist.tipoOrcamento = 2;
						} else if (checklist.qtdAprovados == 0 && checklist.qtdReprovados > 0) {
							checklist.tipoOrcamento = 3;
						} else if (checklist.qtdAprovados == 0 && checklist.qtdReprovados == 0) {
							checklist.tipoOrcamento = 4;
						}
					});
					dadosHistoricoOrcamento.value = checklists ?? [];
				} catch (e){
					showTimeAlert('Não foi possível completar a solicitação', "error");
					dadosHistoricoOrcamento.value = [];
				} finally {
					loadingHistoricoOrcamento.value = false;
				}
			};			

			async function consultoresInit(codConcessionaria, filtersData, codConsultor, placa){
				percentuaisGeral.percentualChecklist = 0
				percentuaisGeral.percentualOrcamento = 0
				percentuaisGeral.totalChecklists = 0
				percentuaisGeral.totalOrcamento = 0
				percentuaisGeral.totalRevisoes = 0
				percentuaisGeral.totalmenteAprovado = 0
				percentuaisGeral.totalmenteReprovado = 0
				percentuaisGeral.percentualOrcamentoAprovado = 0

				try{
					loadingConsultores.value = true
					const response = await getConsultores(codConcessionaria,filtersData,codConsultor,placa)
					
					const { consultores: consultoresData, ...dadosPercentuais } = response
					Object.assign(percentuaisGeral,dadosPercentuais)
					consultores.value = consultoresData
					loadingConsultores.value = false
				} catch(e){
					showTimeAlert("Não foi possível completar a solicitação", "error");
				}
				
			}

			async function mecanicosInit(codConcessionaria,filtersData,codConsultor,placa){
				try {
					loadingMecanicos.value = true
					const response = await getMecanicos(codConcessionaria,filtersData,codConsultor,placa)
					mecanicos.value = response
					loadingMecanicos.value = false
				} catch (error) {
					showTimeAlert("Não foi possível completar a solicitação", "error");
				}
			}

			async function faturamentoEgraficoInit(codConcessionaria,filtersData,codConsultor,placa) : Promise<void>{
				try {
					loadingFaturamentoGraficoDiario.value = true
					const response = await getFaturamento(codConcessionaria,filtersData,codConsultor,placa)
					graficoDiario.value = response.graficoDiario
					Object.assign(faturamentoValores.value,response.faturamentos)
					graficoDiario.value = response.graficoDiario
					loadingFaturamentoGraficoDiario.value = false	
				} catch (error) {
					showTimeAlert('Não foi possível completar a solicitação', "error");
					loadingFaturamentoGraficoDiario.value = false
				}			
			}

			async function graficoHistoricoOrcamentoRealizado(codConcessionaria,filtersData,codConsultor,placa){
				loadingGraficoHistoricoOrcamentoRealizado.value = true
				const response = await getGraficoHistoricoRealizado(codConcessionaria,filtersData,codConsultor,placa)
				historicoOrcamentos.value = response
				loadingGraficoHistoricoOrcamentoRealizado.value = false
			}

			async function listaFinalizados(codConcessionaria, filtersData, codConsultor, placa) {  
				animate.value = true;
					// console.log("dartaaa auiq", filtersData)
				try {
					if(!filters){
					moment.locale('pt-br');
					const startMounth = moment().startOf("month");
					const now = moment();
					historicoContatoPeriodo.value = `${startMounth.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")} até ${now.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")}`;
					} else {
					const periodoSelecionado = filtersData.split("/");
				/* 	  console.log("periodoSelecionado finalizados", periodoSelecionado) */
					const dataInicial = moment(periodoSelecionado[0], "YYYY-MM-DD HH:mm:ss");
					const dataFinal = moment(periodoSelecionado[1], "YYYY-MM-DD HH:mm:ss");
					
					if(!filtersData || filtersData == "null/null"){
						historicoContatoPeriodo.value = ``;
					}else if(dataInicial.isSame(dataFinal, "day") && moment().isSame(dataInicial, "day")){
					historicoContatoPeriodo.value = `hoje`;
					} else if (dataInicial.isSame(dataFinal, "day")) {
					historicoContatoPeriodo.value = `${dataInicial.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")}`;
					} else {
					historicoContatoPeriodo.value = `${dataInicial.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")} até ${dataFinal.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")}`;
					}
					}
					
					emitter.emit('filter-relatorio-completo', {codConcessionaria, filtersData, codConsultor, placa})
					consultoresInit(codConcessionaria, filtersData, codConsultor, placa)
					mecanicosInit(codConcessionaria, filtersData, codConsultor, placa)
					faturamentoEgraficoInit(codConcessionaria, filtersData, codConsultor, placa)
					graficoHistoricoOrcamentoRealizado(codConcessionaria, filtersData, codConsultor, placa)

				var parte1 = {}, parte2 = {}, parte3 = {}, parte4 = {};
					parte1 = getOficinadigitalfinalizadosParte1(codConcessionaria, filtersData, codConsultor, placa);	
					parte2 = {};	
					parte3 = {}	
					parte4 = {}

				 Promise.all([parte1, {},{},{}]).then(responses=>{						
					const data = Object.assign({}, responses[0], responses[1], responses[2],responses[3]);	
								

					if (data !=null) {

						// historicoOrcamentos.value = data.historicoOrcamentos ?? null;
						percentual.value = data.percentual;
						checklistCount.value = data.checklist;
						revisoes.value = data.revisoes;
						totalOrcamentos.value = data.orcamentos.totais;
						orcamentos.value = data.orcamentos.aprovados;
						orcamentosParcial.value = data.orcamentos.parcialmente;
						orcamentoReprovados.value = data.orcamentos.reprovados;
						faturamento.value = data.faturamento;
						// consultores.value = data.consultores;
						// mecanicos.value = data.mecanicos.filter((mecanico) => mecanico.codMecanico);
						aceitaramNegociacao.value = data.aceitaramNegociacao;


						if(totalOrcamentos.value.faturado && aceitaramNegociacao.value?.faturamentoRecuperado) {
							percentualRecuperado.value = (aceitaramNegociacao.value?.faturamentoRecuperado * 100 / totalOrcamentos.value.faturado);
						} else {
							percentualRecuperado.value = 0;
						}

						animate.value = false
					} else {
					console.log("getOficinadigitalfinalizados: response is undefined");
					}
				
					animate.value = false
				 })  .catch(error => {
					// Tratamento de erro, caso alguma das Promises falhe.
					console.error('Erro:', error);
					animate.value = false
				})
					//const response = await getOficinadigitalfinalizados(codConcessionaria, filtersData, codConsultor, placa);		
				// console.log('historicoOrcamentos.value',historicoOrcamentos.value)
				
				} catch(e){
					console.log("catch aqui");
					console.error(e);
				} finally {
					console.log("finally aqui");
				}
			}

			function filtraData() {
				if(datePicker.value == null){
					if(lastChangeIsNull.value){
						return
					};
					lastChangeIsNull.value = true;
					datePicker.value = [moment().startOf("day").subtract(30, 'days'), moment().startOf("day")];
					 filters.value = retornaFiltroData(moment().startOf("day").subtract(30, 'days'), moment().startOf("day"));				
						listaFinalizados(codConcessionaria.value,filters.value, null, null);
						faturamentoDaOficina(filters.value);

					return
				};
				lastChangeIsNull.value = false;
				filters.value = retornaFiltroData(datePicker.value[0], datePicker.value[1]);
				listaFinalizados(codConcessionaria.value, filters.value, null, null);
				faturamentoDaOficina(filters.value);
			};

			function retornaFiltroData(dataInicial, dataFinal){
				dataInicial = dataInicial.toISOString().substr(0, 19).replace("T", " ").replace("03:00:00", "00:00:00");
				dataFinal = dataFinal.toISOString().substr(0, 19).replace("T", " ").replace("03:00:00", "23:59:59");
				const filters = `${dataInicial}/${dataFinal}`;
				return filters;
			};

           return{
				checklistCount,
				totalOrcamentos,
				orcamentos,
				revisoes,
				filters,
				codConcessionaria,
				goTo,
				loader,
				filter,
				consultorSelected,
				datePicker,
				filtraData,
				lastChangeIsNull,
				ptBr,
				consultores,
				mecanicos,
				aceitaramNegociacao,
				orcamento,
				dataFaturamentoTotal,
				loadingFaturamentoTotal,				
				metasConcessionaria,
				temRevisoes,
				aprovados,
				orcamentosParcial,
				orcamentoReprovados,
				title,
				labels,
				percentual,
				historicoOrcamentos,
				faturamento,
				historicoContatoPeriodo,
				animate,
				dadosHistoricoOrcamento,
				dadosChecklist,
				loadingHistoricoOrcamento,
				percentualRecuperado,
				propsConcessionaria,
				loadingConsultores,
				percentuaisGeral,
				faturamentoValores,
				loadingFaturamentoGraficoDiario,
				graficoDiario,
				loadingGraficoHistoricoOrcamentoRealizado
           }
        } 
    });
